<!--资源列表-->
<template>
  <!--路径s-->
  <div class="path">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="24" :md="22" :lg="20" >
        <span><router-link to="/"> 首页</router-link></span> &nbsp; &gt; &nbsp; 产学研资源 <span v-html="pathKey"></span>
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--路径s--> 
  <div class="box-p">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="24" :md="22" :lg="20" >
        <div class="resSearch">
          <div class="box-tit"><router-link to="/activity/">创新资源筛选</router-link></div>
          <p>
            <span>资源分类</span>
            <el-radio-group v-model="rgRes" size="large" @change="rgResChange">
              <el-radio-button v-for="item in resData" :label="item.dictValue" :key="item.dictValue">{{item.dictLabel}}</el-radio-button>
            </el-radio-group>
          </p>
          <p>
            <span>资源产业</span>
            <el-checkbox-group v-model="cbgIndustry" size="large" @change="rgResChange">
              <el-checkbox-button v-for="item in industryData" :label="item.dictValue" :key="item.dictValue">{{item.dictLabel}}</el-checkbox-button>
            </el-checkbox-group>
          </p>
        </div>
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
    <el-row :gutter="0">
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :xs="24" :sm="24" :md="16" :lg="15" class="el-col_p0">        
        <div class="reslist">
            <ul>
                <li v-for="(item,index) in listData" :key="index">
                  <div class="res">
                    <div class="res-tit"><router-link :to="{ path: '/resource/resource', query: { id: item.id }}" target="_blank">{{item.name.substring(0,30)}}</router-link></div>
                    <el-row :gutter="20">
                      <el-col :sm="6" :md="6" class="p_0">
                        <router-link :to="{ path: '/resource/resource', query: { id: item.id }}" target="_blank">
                          <!-- <img :src='showImage(item.userId)' /> -->
                          <el-image :src="showImage(item.userId)" fit="scale-down" class="res-img" />
                        </router-link>
                      </el-col>
                      <el-col :sm="18" :md="18">
                        <div v-html="item.content" class="res-intro"></div>
                      </el-col>
                    </el-row>
                    <div class="res-bottom"><span>发布：{{formatDate(item.createDate)}}</span><span>服务：{{item.num}}</span></div>
                  </div>
                </li>
            </ul>
            <el-empty v-if="listData.length === 0" :image-size="250" description="暂无信息" />
        </div>
        <div class="pagination">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[10, 15, 20, 30, 40]"
              :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total='tableTotal'>
            </el-pagination>
        </div>        
      </el-col>
      <el-col :xs="0" :sm="0" :md="6" :lg="5"></el-col>
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
    </el-row>
  </div> 
</template>

<script>
import {formatDate} from '@/utils/datetime_utils.js'
export default {
  name: 'resourcelist',  
  data () {
    return {
      pathKey: '',
      resKey: '',
      rgRes: [],
      cbgIndustry: [],
      resData: [],
      industryData: [],
      currentPage: 1,
      pagesize: 20,
      tableTotal: 0,
      listData: []
    }
  },
  mounted () {
    if (this.$route.query.key === undefined || this.$route.query.key === '') {
      this.resKey = '' 
      this.pathKey = ''   
    } else {
      this.resKey = decodeURIComponent(this.$route.query.key)
      this.pathKey = '&nbsp; &gt; &nbsp;搜索关键字 ' + this.resKey
    }    
    console.log('this.resKey=', this.resKey)
    this.getListData()
    this.getResData()
    this.getIndustryData()
  },
  methods: {
    // pageSize 新每页条数改变时触发(每页下拉显示数据)
    handleSizeChange (size) {
      this.pagesize = size
      this.getListData()
    },
    // 新当前页改变时触发(点击第几页)
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage
      this.getListData()
    },
    async getListData () {
      console.log('rgRes=', this.rgRes)
      console.log('cbgIndustry=', this.cbgIndustry)      
      var array = []
      // array = Object.keys(this.cbgIndustry).map(val => ({
      //     val: this.cbgIndustry[val]
      // }))
      // console.log(array)
      // let obj = JSON.parse(ress)
      for (let i in this.cbgIndustry ){
          array.push(this.cbgIndustry[i])
      }
      console.log(array)
      // status=1&
      const params = '?size='+ this.pagesize + '&current=' + this.currentPage +'&classId=' + this.rgRes + '&industryId=' + array + '&name=' + this.resKey
      const { data: ret } = await this.$http.get1(this.$api.resourcelist + params)      
      console.log('ret=', ret)
      if (ret.code === 1) {
        this.listData = ret.data.records
        this.tableTotal = ret.data.total
      } else {
        this.$message.error(ret.msg)        
      }     
    },
    // 显示时间格式'yyyy-MM-dd hh:mm:ss'
    formatDate(time){      
      return formatDate(time,'yyyy-MM-dd')
    },
    showImage(val){
      if(val === null) {        
        return '/images/nopic1.jpg'
      } else {        
        var arr = JSON.parse(val)
        var imgurl = this.$api.serverUrl + arr[0].url
        // console.log('IMAGE_URL=', imgurl)       
        return imgurl
      }
    },
    // 获取资源分类数据
    async getResData () {
      const { data: ret } = await this.$http.get(this.$api.dictItemlist + '?dictType=class_type')  
      // console.log('resdata=', ret)    
      if (ret.code === 1) {
        if (ret.data === null) {          
          this.$message({
            message: ret.message,
            type: 'warning'
          })
          return
        }
        this.resData = ret.data
        // console.log('resData=', ret.data)
      }
    },
    // 获取产业数据
    async getIndustryData () {
      const { data: ret } = await this.$http.get(this.$api.dictItemlist + '?dictType=industry_type')
      if (ret.code === 1) {
        if (ret.data === null) {          
          this.$message({
            message: ret.message,
            type: 'warning'
          })
          return
        }
        this.industryData = ret.data
        // console.log('IndustryData=', ret.data)
      }
    },
    rgResChange () {
      // console.log('cgbResChange=', val)
      this.getListData()
    }
  },
  watch: {
    // 监听路由query中key的变化
    $route (to, from) {
      // console.log('from=', from)
      // console.log('to=', to)
      // console.log('from.query.key=', from.query.key)
      // query中key不同时重新查询资源
      if (to.query.key !== from.query.key) {
        this.resKey= decodeURIComponent(to.query.key)
        this.getListData()
        if (this.resKey === '') {
          this.pathKey = ''
        } else {
          this.pathKey = '&nbsp; &gt; &nbsp;搜索关键字 ' + this.resKey
        }        
      }      
    }
  }
}
</script>

<style scoped>
</style>